import React, { useState } from 'react'

import ConfirmationModal from './Common/ConfirmationModal'
import Button from './ui/button'

export default function ConfirmActionButton({
  title,
  message,
  onConfirm,
  isLoading,
  buttonLabel,
  className,
}) {
  const [showConfirm, setShowConfirm] = useState(false)
  const toggle = () => setShowConfirm((showConfirm) => !showConfirm)

  return (
    <>
      <Button color='transparent' className={className} onClick={toggle}>
        {buttonLabel}
      </Button>

      <ConfirmationModal
        toggle={toggle}
        isOpen={showConfirm}
        title={title}
        message={message}
        onConfirm={() => onConfirm(toggle)}
        confirmLoading={isLoading}
      />
    </>
  )
}
