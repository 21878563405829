import {
  Info,
  ListDashes,
  MagicWand,
  PlusCircle,
  TrashSimple,
  WarningOctagon,
} from '@phosphor-icons/react'
import React, { cloneElement } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { useBillData } from '.'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import ControlledCreatableSelect from '../../../components/ControlledSelect/controlled-creatable-select'
import { Box } from '../../../components/ui/box'
import Button from '../../../components/ui/button'
import Shimmer from '../../../components/ui/shimmer'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { mapCurrencyToOption } from '../../../utils/map-to-option'
import {
  FormSectionHr,
  FormSectionTitle,
} from '../../Contract/CreateContract/components/form-section-title'
import { CalculationItem } from '../../new-external-invoice'

export default function CreateBillForm({ control }) {
  const { currencies = [] } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )

  const { vendorsList, categoriesList, loadingData } = useBillData()

  const {
    currency_id: currencyId,
    total_amount: totalAmount,
    items,
  } = useWatch({ control })

  const selectedCurrency = currencies.find((c) => c.id === currencyId)
  const formatter = getCurrencyFormatter(selectedCurrency?.code)

  const totalFormatted = formatter.format(totalAmount)

  const totalCalculated = items?.reduce(
    (acc, item) => acc + Number(item.amount),
    0,
  )
  const totalCalculatedFormatted = formatter.format(totalCalculated)

  const difference = totalAmount > 0 ? totalAmount - totalCalculated : 0
  const differenceFormatted = formatter.format(difference)

  // @todo: should depend on invoice scan
  const isScanned = false

  const { fields, append, remove } = useFieldArray({ control, name: 'items' })

  if (loadingData) {
    return (
      <div className='tw-space-y-4 tw-p-6'>
        <FormSectionTitle title='Details' Icon={Info} />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
        <Shimmer width='100%' height='56px' />
      </div>
    )
  }

  return (
    <div className='tw-grid tw-grid-rows-[1fr_auto] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'>
      <div className='tw-space-y-4 tw-p-6'>
        <FormSectionTitle title='Details' Icon={Info} />

        <ScannedNoticeWrapper
          isScanned={isScanned}
          selectStyles={
            !isScanned
              ? null
              : {
                  control: () => ({
                    borderColor: 'var(--cyan-110)',
                    backgroundColor: 'var(--cyan-10)',
                  }),
                }
          }
        >
          <ControlledCreatableSelect
            control={control}
            name='vendor_id'
            inputId='vendor_id'
            label='Vendor'
            options={vendorsList}
          />
        </ScannedNoticeWrapper>

        <ControlledSelect
          control={control}
          name='category_id'
          inputId='category_id'
          label='Category'
          options={categoriesList}
        />

        <ScannedNoticeWrapper
          isScanned={isScanned}
          inputClassName={
            !isScanned ? null : '!tw-border-cyan-110 !tw-bg-cyan-10'
          }
        >
          <ControlledDatePicker
            control={control}
            name='issue_date'
            id='issue_date'
            label='Issue date'
            placeholder='Issue date'
          />
        </ScannedNoticeWrapper>

        <ScannedNoticeWrapper
          isScanned={isScanned}
          inputClassName={
            !isScanned ? null : '!tw-border-cyan-110 !tw-bg-cyan-10'
          }
        >
          <ControlledDatePicker
            control={control}
            name='due_date'
            id='due_date'
            label='Due date'
            placeholder='Due date'
          />
        </ScannedNoticeWrapper>

        <ScannedNoticeWrapper
          isScanned={isScanned}
          className={!isScanned ? null : '!tw-border-cyan-110 !tw-bg-cyan-10'}
        >
          <ControlledCurrencyInput
            control={control}
            name='total_amount'
            id='total_amount'
            label='Total amount'
            placeholder='Total amount'
            prefix={`${selectedCurrency?.symbol ?? ''} `}
          />
        </ScannedNoticeWrapper>

        <ControlledSelect
          control={control}
          name='currency_id'
          inputId='currency_id'
          label='Currency'
          options={currencies.map((c) => mapCurrencyToOption(c, 'id'))}
        />

        <FormSectionHr />

        <FormSectionTitle title='Items' Icon={ListDashes} />

        {fields?.map((field, index) => {
          return (
            <section
              key={field.id}
              className='tw-space-y-4 tw-rounded tw-border tw-border-surface-30 tw-bg-surface-10 tw-p-4'
            >
              <div className='tw-flex tw-items-center tw-justify-between'>
                <h6 className='tw-mb-0'>Item {index + 1}</h6>

                <Button
                  icon={<TrashSimple size={16} />}
                  type='button'
                  onClick={() => remove(index)}
                  aria-label={`Remove item ${index + 1}`}
                  className='!tw-border-0 !tw-p-1'
                  color='danger'
                  outline
                />
              </div>

              <ControlledInput
                control={control}
                name={`items.${index}.description`}
                id={`items.${index}.description`}
                label='Description'
                placeholder='Description'
              />

              <div className='tw-grid tw-gap-4 xl:tw-grid-cols-3'>
                <ControlledSelect
                  control={control}
                  name={`items.${index}.category_id`}
                  inputId={`items.${index}.category_id`}
                  label='Category'
                  placeholder='Category'
                  options={categoriesList}
                  wrapperClassName='xl:tw-col-span-2'
                />

                <ControlledCurrencyInput
                  control={control}
                  name={`items.${index}.amount`}
                  id={`items.${index}.amount`}
                  label='Amount'
                  placeholder='Amount'
                  prefix={`${selectedCurrency?.symbol ?? ''} `}
                />
              </div>
            </section>
          )
        })}

        <Box
          borderDashed
          tag='button'
          type='button'
          className='tw-flex tw-w-full tw-items-center tw-gap-2 tw-border-primary-100 tw-bg-primary-10 tw-font-bold tw-text-primary-100 tw-transition-colors hover:tw-bg-primary-30 focus-visible:tw-ring-2 focus-visible:tw-ring-primary-80 focus-visible:tw-ring-offset-2'
          onClick={() => {
            append(defaultBillInvoiceItem)
          }}
        >
          <PlusCircle size={24} />
          <span>Add item</span>
        </Box>

        <div className='tw-mt-4 tw-space-y-2 tw-rounded tw-border-b tw-border-surface-30 tw-bg-surface-10 tw-p-6'>
          {[
            ...(!items || items?.length <= 0
              ? []
              : items.map((item, index) => {
                  return {
                    label: item.description || `Item ${index + 1}`,
                    value: formatter.format(item.amount),
                    valueClassName: 'tw-text-sm',
                  }
                })),
            {
              label: 'Total amount',
              value:
                !items || items?.length <= 0
                  ? totalFormatted
                  : totalCalculatedFormatted,
              labelClassName: 'tw-text-sm tw-font-bold tw-text-black',
              valueClassName: 'tw-text-xl tw-font-bold tw-text-black',
            },
          ].map((item) => (
            <CalculationItem key={item.label} {...item} />
          ))}
          {difference === 0 || !items || items?.length <= 0 ? null : (
            <div className='!tw-mt-0 tw-flex tw-items-center tw-justify-end tw-gap-1 tw-text-xs tw-text-systemRed-100'>
              <span>{differenceFormatted} left to itemize</span>
              <WarningOctagon size={16} />
            </div>
          )}
        </div>
      </div>

      <div className='tw-sticky tw-bottom-0 tw-z-10 !tw-flex tw-border-t tw-border-t-surface-30 tw-bg-white tw-p-6'>
        <Button type='submit' className='tw-ml-auto'>
          Continue
        </Button>
      </div>
    </div>
  )
}

function ScannedNoticeWrapper({ children, className, isScanned, ...props }) {
  if (isScanned) {
    return (
      <div className='tw-relative'>
        {cloneElement(children, { className, ...props })}

        <MagicWand
          className='tw-absolute tw-bottom-[calc(42px/2)] tw-left-0 tw-size-7 -tw-translate-x-1/2 tw-translate-y-1/2 tw-rounded-full tw-bg-white tw-p-1 tw-text-base tw-text-cyan-110'
          aria-label='Scanned from the invoice'
        />
      </div>
    )
  }

  return cloneElement(children, { className, ...props })
}

export const defaultBillInvoiceItem = {
  description: '',
  amount: null,
  currency_id: null,
  category_id: null,
}
