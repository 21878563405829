import {
  ArrowLeft,
  Eye,
  Flag,
  Info,
  MapPin,
  MapTrifold,
  Notebook,
  Plus,
  Storefront,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import toastr from 'toastr'

import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import ControlledSelect from '../../../../components/ControlledSelect'
import Head from '../../../../components/head'
import SearchBar from '../../../../components/SearchBar'
import BadgeV2 from '../../../../components/ui/badge-v2'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import CountryFlag, { getFlagUrlFromIso2 } from '../../../../components/ui/flag'
import PageHeading from '../../../../components/ui/page-heading'
import Shimmer from '../../../../components/ui/shimmer'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  sideMenuGridClass,
  SideMenuHeader,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import {
  deleteVendor,
  getVendors,
} from '../../../../services/api-bill-payments'
import { mapListToOption } from '../../../../utils/map-to-option'
import { FormSectionHr } from '../../../Contract/CreateContract/components/form-section-title'
import { EmptyState } from '../../../review-center/empty-state'
import { DetailsInfoList } from '../../../review-center/review-layout-details-components'
import BillCategoryIcon from '../../bill-category-icon'
import {
  DetailSectionTitle,
  getBankDetailsStatusBadgeColor,
  VENDOR_BANK_ACCOUNT_STATUS,
  VendorBankDetails,
} from '../../detail-section-title'
import { BillInfoText } from '../../list'
import { AddBankSideMenu, useBankSideMenu } from './add-bank-side-menu'

const PageHeader = () => (
  <PageHeading className='tw-mb-6'>
    <PageHeading.Title className='tw-text-2xl'>
      <PageHeading.ActionGroup className='tw-flex-col tw-gap-0'>
        <Button
          icon={<ArrowLeft size={24} />}
          color='transparent'
          className='!tw-block !tw-p-0'
          tag={Link}
          to='/bills'
        />
        Vendors
      </PageHeading.ActionGroup>
    </PageHeading.Title>
    <Button
      icon={<Plus size={20} />}
      className='!tw-h-14'
      tag={Link}
      to='/bills/vendors/create'
    >
      Add Vendor
    </Button>
  </PageHeading>
)

const BodyHeader = ({
  onQueryChanged,
  query,
  isLoading,
  control,
  categories,
}) => {
  const wrapperClassName = 'tw-grid tw-grid-rows-2 tw-gap-2 md:tw-grid-cols-2'

  if (isLoading) {
    return (
      <span className={wrapperClassName}>
        <Shimmer width='1fr' />
        <Shimmer width='1fr' />
      </span>
    )
  }

  return (
    <span className={wrapperClassName}>
      <SearchBar
        placeholder='Search by name'
        onQueryChanged={onQueryChanged}
        query={query}
        inputClassName='tw-w-full !tw-h-11'
      />

      <ControlledSelect
        control={control}
        placeholder='Category'
        isClearable
        options={categories?.map(mapListToOption)}
        name='category'
      />
    </span>
  )
}

const DetailsButton = ({
  item,
  fetchVendors,
  activeItem,
  showItem,
  closeItem,
}) => {
  const { isAddBankOpen, toggleAddBank } = useBankSideMenu()

  const {
    bank_account: bankAccount,
    name,
    country,
    category,
    city,
    address,
  } = item
  const iconStyle = 'tw-fill-text-60 tw-size-6'

  const isActive = activeItem === item?.id
  return (
    <>
      <Button
        color='link'
        onClick={() => showItem?.()}
        className='tw-text-sm !tw-text-primary-100'
        icon={<Eye size={16} />}
      >
        Details
      </Button>

      {isActive && (
        <SideMenu
          isOpen
          onClose={() => closeItem?.()}
          itemListClassName={sideMenuGridClass()}
        >
          <SideMenuHeader toggle={() => closeItem?.()}>
            <div className='tw-mr-1 tw-text-xl tw-text-black'>{name}</div>
            <span className='tw-text-sm tw-font-medium tw-text-text-80'>
              Vendor
            </span>
          </SideMenuHeader>

          <SideMenuBody className='tw-flex-1'>
            <DetailsInfoList
              items={[
                {
                  label: 'Vendor',
                  value: <BillInfoText title={name} />,
                  icon: <Storefront className={iconStyle} />,
                },
                {
                  label: 'Category',
                  value: <BillInfoText title={category.name} />,
                  icon: <Notebook className={iconStyle} />,
                },
                {
                  label: 'Country',
                  value: country ? (
                    <span className='tw-flex tw-gap-2'>
                      <BillInfoText title={country.name} />
                      <CountryFlag url={getFlagUrlFromIso2(country.iso2)} />
                    </span>
                  ) : (
                    'N/A'
                  ),
                  icon: <Flag className={iconStyle} />,
                },
                {
                  label: 'City',
                  value: city ? <BillInfoText title={city} /> : 'N/A',
                  icon: <MapTrifold className={iconStyle} />,
                },
                {
                  label: 'Recipient address',
                  value: address ? <BillInfoText title={address} /> : 'N/A',
                  icon: <MapPin className={iconStyle} />,
                },
              ]}
              className='tw-p-0'
              title={
                <DetailSectionTitle
                  title='Details'
                  icon={<Info size={20} className='tw-mr-2 tw-fill-primary' />}
                  containerClassName='tw-mb-2'
                  rightItem={
                    <Button color='link' className='!tw-px-0 !tw-py-1'>
                      Edit
                    </Button>
                  }
                />
              }
            />
            <FormSectionHr className='tw-my-6' />

            <VendorBankDetails
              bankInfo={bankAccount}
              handleAddBankAccount={toggleAddBank}
            />

            <AddBankSideMenu
              isOpen={isAddBankOpen}
              toggle={toggleAddBank}
              updateVendors={() => fetchVendors(undefined, false)}
              vendor={item}
            />
          </SideMenuBody>

          <SideMenuFooter className='tw-justify-between'>
            <DeleteButton
              onSuccess={() => {
                closeItem?.()
                fetchVendors?.()
              }}
              vendor={item}
            />
          </SideMenuFooter>
        </SideMenu>
      )}
    </>
  )
}

const DeleteButton = ({ onSuccess, vendor }) => {
  const [showConfirm, setShowConfirm] = useState()
  const { isLoading: deletingVendor, startFetch: _deleteVendor } = useFetch({
    action: deleteVendor,
    onComplete: () => {
      toastr.success(`Vendor "${vendor.name}" deleted successfully.`)
      onSuccess()
      setShowConfirm(false)
    },
    onError: (error) => toastr.error(error),
  })

  return (
    <>
      <Button
        color='transparent'
        className='!tw-px-0 !tw-text-systemRed'
        onClick={() => setShowConfirm(true)}
      >
        Delete
      </Button>

      <ConfirmationModal
        toggle={() => setShowConfirm(false)}
        isOpen={showConfirm}
        title='Delete Vendor'
        message='Are you sure you want to delete this vendor'
        onConfirm={() => _deleteVendor({ id: vendor.id })}
        confirmLoading={deletingVendor}
      />
    </>
  )
}

const Body = () => {
  const [query, setQuery] = useState('')

  const [activeItem, setActiveItem] = useState(null)

  const { control, watch } = useForm()
  const selectedCategory = watch('category')
  const integrationCategories = useSelector(
    (state) => state.Layout?.staticData?.integration_categories,
  )
  const isFiltering = query || selectedCategory

  const {
    data: vendors,
    isLoading,
    startFetch: fetchVendors,
  } = useFetch(
    {
      action: getVendors,
      autoFetch: true,
      body: { search_key: query, category_id: selectedCategory },
    },
    [query, selectedCategory],
  )

  const columns = [
    { Header: 'Vendor Name', accessor: 'name' },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cellData }) => {
        if (!cellData?.id) return null
        return (
          <span className='tw-flex tw-gap-2'>
            <BillCategoryIcon category={cellData?.id} /> {cellData?.name}
          </span>
        )
      },
    },
    {
      Header: 'Bank Account Details',
      accessor: 'bank_account.screening_status',
      Cell: ({ cellData, rowData }) => {
        const status =
          cellData ||
          (rowData.bank_account
            ? VENDOR_BANK_ACCOUNT_STATUS.PENDING
            : VENDOR_BANK_ACCOUNT_STATUS.MISSING_DETAILS)
        return (
          <BadgeV2
            className={getBankDetailsStatusBadgeColor(status)}
            name={status}
          />
        )
      },
    },
    {
      Cell: ({ rowData }) => (
        <DetailsButton
          item={rowData}
          fetchVendors={fetchVendors}
          activeItem={activeItem}
          showItem={() => setActiveItem(rowData?.id)}
          closeItem={() => setActiveItem(null)}
        />
      ),
    },
  ]
  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
      <BodyHeader
        onQueryChanged={setQuery}
        query={query}
        isLoading={isLoading && !isFiltering}
        control={control}
        categories={integrationCategories}
      />
      {isLoading ? (
        <span className='tw-flex tw-flex-col tw-gap-2'>
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
        </span>
      ) : vendors?.length === 0 ? (
        <EmptyState
          title='You have no vendor to show'
          textElement={
            !isFiltering
              ? 'You can click Add Vendor button and create one.'
              : ''
          }
          pictureElement={
            <Storefront
              weight='duotone'
              className='tw-mb-4 tw-fill-primary'
              size={250}
            />
          }
          className='tw-shadow-none'
        />
      ) : (
        <DataTable columns={columns} data={vendors} striped responsive />
      )}
    </div>
  )
}

export default function Vendors() {
  return (
    <div className='page-content'>
      <Head title='Vendors' />
      <PageHeader />
      <Body />
    </div>
  )
}
