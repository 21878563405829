import {
  CalendarCheck,
  CalendarPlus,
  Coin,
  DownloadSimple,
  Eye,
  Info,
  Invoice,
  ListChecks,
  ListDashes,
  Money,
  Notebook,
} from '@phosphor-icons/react'
import { differenceInDays, format } from 'date-fns'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { BILL_STATUS, BillInfoText } from '.'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { TimelineType2 } from '../../Contract/ContractPage/item-timeline'
import { FormSectionHr } from '../../Contract/CreateContract/components/form-section-title'
import { DetailsInfoList } from '../../review-center/review-layout-details-components'
import { DetailSectionTitle, VendorBankDetails } from '../detail-section-title'
import { rpFormatDate } from '../../../utils/formatters/date-picker-date-format'
import { cn } from 'ui'
import { formatDays } from '../../../utils/formatters/format-days'
import ConfirmActionButton from '../../../components/confirm-action-button'
import toastr from 'toastr'
import { useFetch } from '../../../helpers/hooks'
import { approveBill, deleteBill } from '../../../services/api-bill-payments'

export const getBillStatusBadgeColor = (status) => {
  if (status.toLowerCase().includes('failed')) {
    return 'danger'
  }
  return 'warning'
}

export const BillStatusBadge = ({ status }) => (
  <BadgeV2 status={getBillStatusBadgeColor(status)} name={status} />
)

export function BillDueDate({ date, titleClassName }) {
  const diff = differenceInDays(new Date(date), new Date())
  const isPast = diff < 0
  return (
    <BillInfoText
      title={rpFormatDate(date, 'yyyy-MM-dd', 'dd/MM/yyyy')}
      subTitle={
        <span className={cn({ 'tw-text-systemRed': isPast })}>
          {isPast
            ? `${formatDays(Math.abs(diff))} overdue`
            : `Due in ${formatDays(diff)}`}
        </span>
      }
      className={cn(
        'tw-text-left',
        { 'tw-text-systemRed': isPast },
        titleClassName,
      )}
    />
  )
}

const FooterLeft = ({ tab, bill, onComplete }) => {
  const { isLoading: deletingBill, startFetch: _deleteBill } = useFetch({
    action: deleteBill,
    onComplete: (_, body) => {
      toastr.success(`Bill "${bill.name}" successfully deleted.`)
      body.toggle()
      onComplete()
    },
    onError: (error) => toastr.error(error),
  })

  if (tab === BILL_STATUS.DRAFT) {
    return (
      <ConfirmActionButton
        buttonLabel='Delete'
        className='!tw-px-0 !tw-text-systemRed'
        title='Delete Bill'
        message='Are you sure you want to delete this bill?'
        onConfirm={(toggle) => _deleteBill({ id: bill.id, toggle })}
        isLoading={deletingBill}
      />
    )
  }

  if (tab === BILL_STATUS.PENDING) {
    return (
      <ConfirmActionButton
        buttonLabel='Decline'
        className='!tw-bg-systemRed !tw-text-white'
        title='Decline Bill'
        message='Are you sure you want to decline this bill?'
        onConfirm={(toggle) => _deleteBill({ id: bill.id, toggle })}
        isLoading={deletingBill}
      />
    )
  }
}

const FooterRight = ({ tab, bill }) => {
  const { isLoading: approvingBill, startFetch: _approveBill } = useFetch({
    action: approveBill,
  })

  if (tab === BILL_STATUS.PENDING) {
    return (
      <ConfirmActionButton
        buttonLabel='Approve'
        title='Approve Bill'
        message='Are you sure you want to approve this bill?'
        className='!tw-bg-systemGreen !tw-text-white'
        onConfirm={(toggle) => _approveBill({ id: bill.id, toggle })}
        isLoading={approvingBill}
      />
    )
  }

  if (tab === BILL_STATUS.READY) {
    return (
      <ConfirmActionButton
        buttonLabel='Decline'
        className='!tw-bg-systemRed !tw-text-white'
        title='Decline Bill'
        message='Are you sure you want to decline this bill?'
      />
    )
  }
}

const BillEditButton = ({ isDraft }) => (
  <ConfirmActionButton
    buttonLabel='Edit'
    title='Edit Bill'
    message='Are you sure you want to edit this bill?'
    className={cn('!tw-border !tw-border-surface-30', { '!tw-mr-2': !isDraft })}
  />
)

export default function BillDetailsButton({ bill, fetchBills, activeTab }) {
  const [showDetails, setShowDetails] = useState(false)
  const integrationCategories = useSelector(
    (state) => state.Layout?.staticData?.integration_categories,
  )
  const user = useSelector((state) => state.Account?.user)

  const {
    currency,
    id,
    vendor,
    created_at: createdAt,
    due_date: dueDate,
    amount,
    file,
    items,
    creator_id: creatorId,
    creator_name: creatorName,
    category_id: category,
    timeline,
  } = bill
  const formatter = getCurrencyFormatter(currency.code)
  const iconStyle = 'tw-fill-text-60 tw-size-6'

  return (
    <>
      <Button
        color='link'
        onClick={() => setShowDetails(true)}
        className='tw-text-sm !tw-text-primary-100'
        icon={<Eye size={16} />}
      >
        Details
      </Button>

      {showDetails && (
        <SideMenu isOpen onClose={() => setShowDetails(false)}>
          <SideMenuHeader toggle={() => setShowDetails(false)}>
            <div className='tw-flex tw-items-center'>
              <span className='tw-mr-1 tw-text-xl tw-text-black'>{id}</span>
              <BillStatusBadge status={activeTab.label} />
            </div>
            <span className='tw-text-sm tw-font-medium tw-text-text-80'>
              {vendor?.name}
            </span>
          </SideMenuHeader>

          <SideMenuBody>
            <DetailsInfoList
              items={[
                {
                  label: 'Created on',
                  value: (
                    <BillInfoText
                      title={format(new Date(createdAt), 'dd/MM/yyyy')}
                      subTitle={
                        creatorName &&
                        `By ${creatorName} ${user.id === creatorId ? '(You)' : ''}`
                      }
                    />
                  ),
                  icon: <CalendarPlus className={iconStyle} />,
                },
                {
                  icon: <Notebook className={iconStyle} />,
                  label: 'Category',
                  value: (
                    <BillInfoText
                      title={
                        integrationCategories.find((cat) => cat.id === category)
                          .name
                      }
                    />
                  ),
                },
                {
                  label: 'Due date',
                  value: (
                    <BillDueDate
                      date={dueDate}
                      titleClassName='tw-text-right'
                    />
                  ),
                  icon: <CalendarCheck className={iconStyle} />,
                },
                {
                  label: 'Amount',
                  value: <BillInfoText title={formatter.format(amount)} />,
                  icon: <Money className={iconStyle} />,
                },
                {
                  label: 'Currency',
                  value: (
                    <BillInfoText
                      title={`${currency.code} - ${currency.name} (${currency.symbol})`}
                    />
                  ),
                  icon: <Coin className={iconStyle} />,
                },
              ]}
              className='tw-p-0'
              title={
                <DetailSectionTitle
                  title='Details'
                  icon={<Info size={20} className='tw-mr-2 tw-fill-primary' />}
                  className='tw-mb-2'
                />
              }
            />
            <FormSectionHr className='tw-my-6' />

            {file && (
              <>
                <DetailsInfoList
                  items={[
                    {
                      label: (
                        <span className='tw-flex tw-items-center'>
                          <img
                            src={file.thumbnail}
                            alt='invoice'
                            className='tw-mr-4 tw-size-12 tw-rounded tw-border tw-border-surface-30'
                          />
                          <BillInfoText
                            title='Invoice'
                            subTitle='invoice12_RT.PDF'
                            className='tw-text-left'
                          />
                        </span>
                      ),
                      value: (
                        <Button
                          iconRight={
                            <DownloadSimple className='tw-ml-2' size={20} />
                          }
                          color='link'
                          className='!tw-px-0'
                          target='_blank'
                          rel='noreferrer'
                          download
                          tag='a'
                          href={file.pdf}
                        >
                          Download
                        </Button>
                      ),
                    },
                  ]}
                  className='tw-p-0'
                  title={
                    <DetailSectionTitle
                      title='Files'
                      icon={
                        <Invoice
                          size={20}
                          className='tw-mr-2 tw-fill-primary'
                        />
                      }
                    />
                  }
                />
                <FormSectionHr className='tw-my-6' />
              </>
            )}

            <DetailsInfoList
              items={items.map((item) => ({
                label: (
                  <BillInfoText
                    title={item.description}
                    subTitle={
                      integrationCategories.find(
                        (category) => category.id === item.category_id,
                      ).name
                    }
                    className='tw-text-left'
                  />
                ),
                value: (
                  <BillInfoText
                    title={`${currency.symbol} ${item.amount}`}
                    subTitle='Amount'
                  />
                ),
              }))}
              title={
                <DetailSectionTitle
                  title='Line items'
                  icon={
                    <ListDashes size={20} className='tw-mr-2 tw-fill-primary' />
                  }
                />
              }
              className='tw-p-0'
            />
            <FormSectionHr className='tw-my-6' />

            {bill.vendor?.bank_account && (
              <VendorBankDetails bankInfo={bill.vendor?.bank_account} />
            )}
            <FormSectionHr className='tw-my-6' />

            <TimelineType2
              flowTimeline={timeline}
              title={
                <span className='tw-flex tw-items-center tw-text-base tw-font-bold tw-text-black'>
                  <ListChecks size={20} className='tw-mr-2 tw-fill-primary' />
                  Approvals
                </span>
              }
            />
          </SideMenuBody>

          <SideMenuFooter className='tw-sticky tw-bottom-0 tw-left-0 tw-right-0 tw-justify-between tw-bg-white'>
            <FooterLeft
              tab={activeTab.key}
              bill={bill}
              onComplete={() => {
                setShowDetails(false)
                fetchBills()
              }}
            />

            <span>
              <BillEditButton isDraft={activeTab.key === BILL_STATUS.DRAFT} />

              <FooterRight
                tab={activeTab.key}
                bill={bill}
                onComplete={() => {
                  setShowDetails(false)
                  fetchBills()
                }}
              />
            </span>
          </SideMenuFooter>
        </SideMenu>
      )}
    </>
  )
}
