import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Sparkle } from '@phosphor-icons/react'
import { AutomationsCard } from './automations-card'

export function AutomationsTemplatesModal(props) {
  const { toggle, isOpen, templates } = props

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='xl'>
      <ModalHeader toggle={toggle}>Choose an option</ModalHeader>
      <ModalBody>
        <div className='tw-align-items-center tw-my-6 tw-flex tw-flex-row tw-gap-2'>
          <Sparkle className='tw-text-primary' size={20} />
          <span className='tw-text-lg tw-font-medium'>Suggested for you</span>
        </div>
        <div className='tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 lg:tw-grid-cols-3'>
          {templates?.map((t) => (
            <AutomationsCard
              key={t._id}
              containerClassName='tw-bg-secondary-10'
              id={t._id}
              title={t.name}
              description={t?.description}
            />
          ))}
        </div>
      </ModalBody>
    </Modal>
  )
}
