import React from 'react'
import { Controller } from 'react-hook-form'

import CustomCreatableSelect from '../Forms/CustomSelect/custom-creatable'

export default function ControlledCreatableSelect({
  control,
  name,
  transform,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur, onChange, ref },
        fieldState: { error },
      }) => {
        return (
          <CustomCreatableSelect
            name={name}
            value={
              typeof transform?.input === 'function'
                ? transform.input(value)
                : value
            }
            onChange={(...args) =>
              typeof transform?.output === 'function'
                ? onChange(transform.output(...args))
                : onChange(...args)
            }
            onBlur={onBlur}
            selectRef={ref}
            // aria-invalid={!!error}
            // aria-errormessage={name + '-error-msg'}
            error={error}
            {...props}
          />
        )
      }}
    />
  )
}
