import React from 'react'
import { ActionsDropdown } from 'ui'
import { EnvelopeSimple } from '@phosphor-icons/react'
import Pagination from '../../../../components/ui/pagination'
import { format } from 'date-fns'
import DataTable from '../../../../components/ui/data-table'
import { useHistory } from 'react-router-dom'

export function AutomationTable(props) {
  const {
    automations = [],
    pagination: { onPageChange, page, total, perPage } = {},
  } = props

  const history = useHistory()

  const columns = [
    {
      Header: 'Automation title',
      accessor: 'name',
      Cell: ({ rowData }) => (
        <>
          <span className='tw-font-bold'>{rowData.name}</span>
          <br />
          <span className='tw-mr-10 tw-font-light'>{rowData.description}</span>
        </>
      ),
    },
    {
      Header: 'Created on',
      accessor: 'created_at',
      Cell: ({ rowData }) => {
        const date = new Date(rowData.created_at)
        return (
          <>
            {format(date, 'yyyy-mm-dd')}
            <br />
            <span className='tw-text-s tw-text-secondary-80'>
              {format(date, 'HH:ss')}
            </span>
          </>
        )
      },
    },
    {
      Header: 'Last modified',
      accessor: 'updated_at',
      Cell: ({ rowData }) => {
        const date = new Date(rowData.updated_at)
        return (
          <>
            {format(date, 'yyyy-mm-dd')}
            <br />
            <span className='tw-text-s tw-text-secondary-80'>
              {format(date, 'HH:ss')}
            </span>
          </>
        )
      },
    },
    {
      Header: 'Action type',
      accessor: 'action_channels',
      Cell: ({ rowData }) => (
        <>
          {(rowData.action_channels ?? []).includes('EMAIL') ? (
            <div className='tw-flex tw-gap-1'>
              <EnvelopeSimple size={20} className='tw-text-primary' />
              <span>Email</span>
            </div>
          ) : null}
        </>
      ),
    },
    {
      Header: '',
      Cell: ({ rowData }) => (
        <ActionsDropdown
          data={[
            {
              label: 'Edit',
              onClick: () => history.push(`/automations/add?id=${rowData._id}`),
            },
          ]}
        />
      ),
    },
  ]
  return (
    <>
      <DataTable striped columns={columns} data={automations} />
      <div className='tw-w-100 tw-my-3 tw-flex tw-justify-end'>
        <Pagination
          activePage={page}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          onChange={onPageChange}
        />
      </div>
    </>
  )
}
