import {
  Asterisk,
  Bank,
  Coin,
  EnvelopeSimple,
  Flag,
  GlobeSimple,
  IdentificationBadge,
  IdentificationCard,
  MapPin,
  MapPinLine,
  MapTrifold,
  Textbox,
  WarningOctagon,
} from '@phosphor-icons/react'
import React from 'react'

import { cn } from 'ui'
import BadgeV2 from '../../components/ui/badge-v2'
import { DetailsInfoList } from '../review-center/review-layout-details-components'
import Alert from '../../components/ui/alert'
import Button from '../../components/ui/button'

export function DetailSectionTitle({
  title,
  icon,
  className,
  rightItem,
  containerClassName,
}) {
  return (
    <span className={cn('tw-flex tw-justify-between', containerClassName)}>
      <span
        className={cn(
          'tw-flex tw-items-center tw-text-base tw-font-bold tw-text-black',
          className,
        )}
      >
        {icon}
        {title}
      </span>

      {rightItem}
    </span>
  )
}

function BankDetailValue({ value }) {
  return <span className='tw-font-semibold tw-text-black'>{value}</span>
}

export const VENDOR_BANK_ACCOUNT_STATUS = {
  // @todo: Figure out what these status really are from the BE
  MISSING_DETAILS: 'missing bank details',
  // in_review: 'bank details in review',
  // failed: 'bank details verification failed',
  // unpaid: 'unpaid',
  // waiting_for_funds: 'waiting for funds',
  // processing: 'processing',
  // paid: 'paid',

  PENDING: 'pending',
  ON_HOLD: 'onhold',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}

export const getBankDetailsStatusBadgeColor = (status) => {
  switch (status?.toLowerCase()) {
    // case VENDOR_BANK_ACCOUNT_STATUS.paid:
    case VENDOR_BANK_ACCOUNT_STATUS.APPROVED:
      return '!tw-bg-systemGreen-20 !tw-text-systemGreen-110'
    // case VENDOR_BANK_ACCOUNT_STATUS.failed:
    case VENDOR_BANK_ACCOUNT_STATUS.MISSING_DETAILS:
    case VENDOR_BANK_ACCOUNT_STATUS.REJECTED:
      return '!tw-bg-systemRed-20 !tw-text-systemRed-110'
    default:
      return '!tw-bg-systemGold-20 !tw-text-systemGold-110'
  }
}

export function VendorBankDetails({
  bankInfo,
  className,
  handleAddBankAccount,
}) {
  const { details, screening_status: status } = bankInfo ?? {}
  const bankAccountStatus =
    status ||
    (details
      ? VENDOR_BANK_ACCOUNT_STATUS.PENDING
      : VENDOR_BANK_ACCOUNT_STATUS.MISSING_DETAILS)

  return (
    <>
      <div className={details && 'tw-flex tw-items-center'}>
        <DetailSectionTitle
          title='Bank details'
          icon={<Bank size={20} className='tw-mr-2 tw-fill-primary' />}
        />
        {details ? (
          <BadgeV2
            name={bankAccountStatus}
            className={cn(
              'tw-ml-2',
              getBankDetailsStatusBadgeColor(bankAccountStatus),
            )}
          />
        ) : null}
      </div>

      {details ? (
        <DetailsInfoList
          className={cn('tw-p-0', className)}
          items={(bankInfo?.details ?? [])?.map(({ name: label, value }) => {
            return {
              label,
              icon: <BankDetailIcon label={label} size={24} />,
              value: <BankDetailValue value={value} />,
              className: 'tw-flex-nowrap',
              valueClassName: 'tw-text-wrap tw-text-right',
            }
          })}
        />
      ) : (
        <Alert
          className='tw-mt-5 !tw-text-wrap !tw-border-systemRed-20 !tw-bg-systemRed-20 !tw-p-4 !tw-text-xs !tw-text-text-80'
          customIcon={
            <WarningOctagon size={20} className='tw-fill-systemRed' />
          }
          iconClassName='[--icon-size:1.25rem]'
          innerClassName='tw-mt-0'
          innerTag='div'
        >
          <p className='tw-mb-0'>
            Bank details are missing for this vendor, make sure to add them in
            order to pay bills.
          </p>

          {!handleAddBankAccount ||
          typeof handleAddBankAccount !== 'function' ? null : (
            <Button
              color='link'
              className='tw-mt-2 !tw-px-0 !tw-py-1 !tw-text-primary'
              onClick={handleAddBankAccount}
            >
              Add Bank Details
            </Button>
          )}
        </Alert>
      )}
    </>
  )
}

function BankDetailIcon({ label, className = 'tw-fill-text-60', size = 24 }) {
  switch (label.toLowerCase()) {
    case 'beneficiary name': {
      return <Textbox size={size} className={className} />
    }
    case 'beneficiary type': {
      return <IdentificationBadge size={size} className={className} />
    }
    case 'account number':
    case 'bank name': {
      return <Bank size={size} className={className} />
    }
    case 'bic code':
    case 'swift code': {
      return <GlobeSimple size={size} className={className} />
    }
    case 'account holder':
    case 'iban': {
      return <IdentificationCard size={size} className={className} />
    }
    case 'country': {
      return <Flag size={size} className={className} />
    }
    case 'state': {
      return <MapPinLine size={size} className={className} />
    }
    case 'city': {
      return <MapTrifold size={size} className={className} />
    }
    case 'currency': {
      return <Coin size={size} className={className} />
    }
    case 'post code': {
      return <EnvelopeSimple size={size} className={className} />
    }
    case 'first line':
    case 'second line': {
      return <MapPin size={size} className={className} />
    }
    default: {
      return <Asterisk size={size} className={className} />
    }
  }
}
