import React, { useState } from 'react'
import { ClockCountdown, Play } from '@phosphor-icons/react'
import { cn } from 'ui'

export function Divider(props) {
  const { last, onClick } = props
  const [adding, setAdding] = useState(false)
  return (
    <div className='tw-ml-5 tw-flex tw-flex-row tw-items-center'>
      <div className='tw-flex tw-w-10 tw-flex-col tw-items-center'>
        <div className='tw-h-6 tw-w-0.5 tw-border tw-border-secondary-30' />
        <button
          onClick={() => setAdding((prv) => !prv)}
          className={cn(
            'tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-2xl',
            adding && 'tw-bg-primary tw-text-white',
          )}
        >
          +
        </button>
        <div
          className={cn(
            `tw-h-6 tw-w-0.5 tw-border tw-border-secondary-30`,
            last && 'tw-hidden',
          )}
        />
      </div>

      {adding ? (
        <div className='tw-ml-2 tw-rounded-full tw-bg-white tw-p-1'>
          <div className='tw-flex tw-items-center tw-gap-2 tw-rounded-full tw-border-2 tw-border-secondary-30 tw-p-2'>
            <button
              onClick={() => {
                onClick({ type: 'action' })
                setAdding(false)
              }}
              className='tw-flex tw-items-center tw-gap-1'
            >
              <Play />
              <span className='hover:tw-text-primary'>Action</span>
            </button>
            <span className='tw-text-secondary-30'>|</span>
            <button
              onClick={() => {
                onClick({ type: 'delay' })
                setAdding(false)
              }}
              className='tw-flex tw-items-center tw-gap-1'
            >
              <ClockCountdown />
              <span className='hover:tw-text-primary'>Delay</span>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}
