import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
} from 'reactstrap'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import omit from 'lodash/omit'
import { filters } from './constants'
import { PlusCircle } from '@phosphor-icons/react'
import Button from '../../../../components/ui/button'

export function AddFilterButtons() {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen((prevState) => !prevState)}
    >
      <DropdownToggle
        tag={(props) => (
          <Button
            outline={!dropdownOpen}
            circle
            iconRight={
              <PlusCircle
                size={20}
                style={{ marginLeft: '-5px' }}
                weight='fill'
              />
            }
            size='sm'
            {...props}
          />
        )}
      >
        Add Filter
      </DropdownToggle>
      <DropdownMenu>
        <Controller
          name='filters'
          render={({ field: { value, onChange } }) => (
            <div className='tw-flex tw-max-h-[300px] tw-w-[250px] tw-flex-col tw-gap-2 tw-overflow-auto tw-px-2 tw-py-2'>
              {filters.all?.map((f, index) => {
                const checked = value?.[f?.filter]
                return (
                  <DropdownItem
                    onClick={() =>
                      onChange(
                        checked
                          ? omit(value, [f.filter])
                          : {
                              ...value,
                              [f.filter]: [],
                            },
                      )
                    }
                    key={`${f.text}-${index}`}
                    header={f?.type === 'header'}
                  >
                    {f.type !== 'header' ? (
                      <Input defaultChecked={checked} type='checkbox' />
                    ) : null}

                    {f.text}
                  </DropdownItem>
                )
              })}
            </div>
          )}
        />
      </DropdownMenu>
    </Dropdown>
  )
}
