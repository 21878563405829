import React from 'react'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../settings-section-heading'
import { PlusCircle, Queue } from '@phosphor-icons/react'
import TabEmpty from '../../../Contract/components/tab/tab-empty'
import { AutomationTable } from './automation-table'
import { useHistory } from 'react-router-dom'
import { useAutomations } from './use-automations'
import Loader from '../../../../components/ui/loader'
import { AutomationsTemplates } from './automations-templates'
import Button from '../../../../components/ui/button'

export function AutomationsTab() {
  const { automations, pagination, isLoading } = useAutomations({
    onlyTemplates: false,
  })
  const history = useHistory()

  return (
    <div className='tw-p-6'>
      {isLoading ? (
        <Loader minHeight='28rem' />
      ) : (
        <>
          <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 md:tw-flex-nowrap'>
            <div>
              <SettingSectionHeading learnMoreLink='https://help.remotepass.com/en/articles/10021964-Automations'>
                My automations
              </SettingSectionHeading>

              <SettingSectionSubHeading className='tw-mb-0'>
                Create automated alerts
              </SettingSectionSubHeading>
            </div>

            {automations.length ? (
              <Button
                onClick={() => history.push('/automations/add')}
                color='link'
                className='tw-align-self-center tw-mr-2 !tw-px-0'
              >
                Add New
              </Button>
            ) : null}
          </div>

          <div className='tw-mt-4'>
            {automations?.length ? (
              <AutomationTable
                automations={automations}
                pagination={pagination}
              />
            ) : (
              <>
                <TabEmpty
                  className='tw-p-0'
                  title='No automation set up yet'
                  subtitle='Alert set up will be listed here'
                  icon={<Queue size={250} className='tw-text-primary' />}
                >
                  <Button
                    icon={<PlusCircle size={20} />}
                    circle={false}
                    onClick={() => history.push('/automations/add')}
                    className='tw-mt-2'
                    text='Add new'
                  />
                </TabEmpty>
              </>
            )}
          </div>
        </>
      )}

      <AutomationsTemplates />
    </div>
  )
}
