import toastr from 'toastr'

import { useFetch } from '../../helpers/hooks'
import { createVendorBankAccount } from '../../services/api-bill-payments'
import deepen from '../../utils/deepen'
import {
  BANK_ACCOUNT_FORM_FIELDS,
  getFieldValueFromData,
} from './add-bank-account-v2'

export function useCreateVendorBankAccount({
  onSuccess,
  onResolved,
  onError,
  successMessage,
}) {
  const { startFetch: createBankAccount, isLoading } = useFetch({
    action: createVendorBankAccount,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          'Something went wrong while creating the vendor bank account',
        )
        onError?.()
      } else {
        const toastMessage = Array.isArray(successMessage)
          ? successMessage
          : [successMessage]
        toastr.success(...toastMessage)
        onSuccess?.()
      }

      onResolved?.()
    },
    onError: (err) => {
      toastr.error(err)
      onResolved?.()
      onError?.()
    },
  })

  return { createBankAccount, isLoading }
}

export function getBankAccountData(values, { user: { firstName, lastName } }) {
  const fieldsToRender = values?.[BANK_ACCOUNT_FORM_FIELDS]
  const keys = fieldsToRender?.map((e) => e.key) ?? []

  const bankAccountData = Object.fromEntries(
    keys?.map((key) => {
      return [key, getFieldValueFromData(values, key)]
    }) ?? [],
  )

  // #region We need to send the bankName field if we have it
  if (keys.includes('bankCode')) {
    const banks = fieldsToRender.find(
      (e) => e.key === 'bankCode',
    )?.valuesAllowed
    if (banks?.length > 0) {
      const theBank = banks.find((e) => e.key === values.bankCode)
      bankAccountData.bankName = theBank?.name
    }
  }
  if (keys.includes('branchCode')) {
    const branches = fieldsToRender.find(
      (e) => e.key === 'branchCode',
    )?.valuesAllowed
    if (branches?.length > 0) {
      const theBranch = branches.find((e) => e.key === values.branchCode)
      bankAccountData.branchName = theBranch?.name
    }
  }
  if (keys.includes('swiftCode')) {
    const field = fieldsToRender.find((e) => e.key === 'swiftCode')
    if (field.type === 'select') {
      const banks = field?.valuesAllowed
      const theBank = banks.find((e) => e.key === values.swiftCode)
      bankAccountData.bankName = theBank?.name
    }
  }
  // #endregion

  const deepBankAccountData = deepen(bankAccountData)

  const enrichedData = {
    currency: values.currency,
    legalType: values.legalType,
    type: values.type,
    accountHolderName: [firstName, lastName].filter(Boolean).join(' '),
    ...deepBankAccountData,
  }

  return enrichedData
}
