import React from 'react'
import { EnvelopeSimple, Eye } from '@phosphor-icons/react'
import slackLogo from '../../../../assets/images/brands/slack.svg'
import { cn } from 'ui'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { createAutomationFromTemplate } from '../../../../services/api-automations'
import toastr from 'toastr'

export function AutomationsCard(props) {
  const { title, id, description, containerClassName } = props
  const history = useHistory()
  const { isLoading, startFetch } = useFetch({
    action: createAutomationFromTemplate,
    isOpenApi: true,
    body: { templateId: id },
    onComplete: (data) => {
      history.push(`/automations/add?id=${data?._id}`)
    },
    onError: (err) => toastr.error(err),
  })
  return (
    <div
      className={cn(
        'tw-flex tw-flex-col tw-bg-white tw-px-3 tw-py-2',
        containerClassName,
      )}
    >
      <div className='tw-flex tw-flex-row'>
        <div className='tw-flex-fill tw-flex tw-flex-row tw-gap-x-2'>
          <div className='tw-bg-secondary-10 tw-p-2'>
            <EnvelopeSimple fill='black' size={20} />
          </div>
          <div className='tw-bg-secondary-10 tw-p-2'>
            <img src={slackLogo} style={{ width: 20, height: 20 }} />
          </div>
        </div>
        <div className='tw-bg-secondary-10 tw-p-2'>
          <Eye fill='black' size={20} />
        </div>
      </div>
      <div className='tw-my-6 tw-flex tw-flex-col'>
        <span className='tw-font-bold'>{title}</span>
        <span className='tw-mr-10'>{description}</span>
      </div>
      <Button
        className='tw-mb-2'
        loading={isLoading}
        onClick={() => startFetch()}
        text='Set Up'
        color='secondary'
        outline
      />
    </div>
  )
}
