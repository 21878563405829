import React from 'react'
import ReactQuill from 'react-quill'
import { Controller } from 'react-hook-form'
import 'react-quill/dist/quill.snow.css'
import 'bootstrap/dist/css/bootstrap.min.css'

export function ActionEmailBody(props) {
  const { index } = props

  /* const addPlaceholder = () => {
    if (placeholder) {
      setHtmlContent(
        (prevContent) =>
          `${prevContent} <span class="placeholder">{${placeholder}}</span>`,
      )
      setPlaceholder('')
    }
  }
*/
  return (
    <Controller
      name={`steps.${index}.body`}
      render={({ field: { value, onChange } }) => (
        <ReactQuill
          value={value}
          className='tw-flex tw-flex-col-reverse tw-rounded tw-border tw-border-surface-130 [&>*:nth-child(2)]:!tw-p-[15px]'
          onChange={onChange}
          placeholder='Email body'
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ list: 'ordered' }, { list: 'bullet' }],
            ],
          }}
        />
      )}
    ></Controller>
  )
}
