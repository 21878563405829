import React from 'react'
import CreatableSelect from 'react-select/creatable'

import customStyles from '../../Common/react-select-custom-styles'
import InputFeedback from '../../ui/input-feedback'
import { getInputErrorMessage } from '../get-input-error-message'

export default function CustomCreatableSelect({
  error,
  label,
  containerClassName,
  ...props
}) {
  return (
    <div className={containerClassName}>
      {!label ? null : <label htmlFor={props.inputId}>{label}</label>}

      <CreatableSelect {...props} styles={customStyles} />

      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}
