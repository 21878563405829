import React from 'react'

import { cn } from 'ui'

export default function UploadPreview({
  preview,
  className = 'tw-h-full tw-w-full',
  imagePreviewClassName,
  style,
}) {
  const classList = cn('tw-rounded tw-border', className)

  return preview?.type === 'application/pdf' ? (
    <iframe
      src={preview?.data + '#toolbar=0&navpanes=0&scrollbar=0'}
      className={classList}
      style={style}
    />
  ) : (
    <div
      style={style}
      className={cn('tw-overflow-auto', imagePreviewClassName)}
    >
      <img alt='' src={preview?.data} className={classList} />
    </div>
  )
}
