export const triggers = [
  {
    label: 'Contract Pending Worker Signature',
    value: 'CONTRACTOR_INVITED',
  },
  {
    label: 'Contract Pending Company Signature',
    value: 'CLIENT_INVITED',
  },
  {
    label: 'Contract Activated',
    value: 'CONTRACT_ACTIVATED',
  },
  {
    label: 'Contract Terminated',
    value: 'CONTRACT_TERMINATED',
  },
  {
    label: 'Contract Amended',
    value: 'CONTRACT_AMENDED',
  },
]

export const filters = {
  all: [
    { type: 'header', text: 'Contract' },
    {
      text: 'Contract Type',
      filter: 'contract',
    },
    {
      text: 'Contract country',
      filter: 'contractCountry',
    },
    {
      text: 'Contract entity',
      filter: 'contractEntity',
    },
    {
      text: 'Contract Tag',
      filter: 'contractTag',
    },
    {
      text: 'Contract status',
      filter: 'contractStatus',
    },
  ],
  contract: [
    { text: 'Fixed', value: 'fixed' },
    { text: 'Milestone', value: 'milestone' },
  ],
}

export const delayTypeOfDays = [{ label: 'Working Days', value: 'workingDays' }]
