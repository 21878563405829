import { Card, Container } from 'reactstrap'
import entries from 'lodash/entries'
import ModalHeader from '../../../../components/ModalHeader'
import React, { Fragment, useCallback, useState } from 'react'
import Head from '../../../../components/head'
import { useHistory, useLocation } from 'react-router-dom'
import { Funnel, Play } from '@phosphor-icons/react'
import { Action } from './action'
import { Delay } from './delay'
import { Trigger } from './trigger'
import { Divider } from './divider'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { TitleEditText } from './automation-title-edit-text'
import { AddFilterButtons } from './add-filter-buttons'
import { filters } from './constants'
import { FilterButtons } from './filter-buttons'
import { QuitConfirmModal } from './quit-confirm-modal'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { createAutomation } from '../../../../services/api-automations'
import toastr from 'toastr'
import {
  transformStepsRequest,
  transformAutomationResponse,
  updateAutomation,
  defaultValue,
} from './tools'
import { useAutomationDetail } from './use-automation-detail'
import Loader from '../../../../components/ui/loader'

export function NewAutomationModal() {
  const history = useHistory()
  const [quitModal, setQuitModal] = useState(false)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const { isLoading, data } = useAutomationDetail({ id })
  const currentAutomation = transformAutomationResponse(data)
  const methods = useForm({
    defaultValues: {
      focusedNode: 'trigger',
      ...(id ? {} : defaultValue),
    },
    values: id ? currentAutomation : undefined,
  })
  const stepsField = useFieldArray({
    control: methods.control,
    name: 'steps',
  })

  const { isLoading: isCreating, startFetch: _createAutomation } = useFetch({
    action: createAutomation,
    isOpenApi: true,
    onComplete: (res, data) => {
      _updateSteps({
        ...data,
        id: res._id,
      })
    },
    onError: (err) => toastr.error(err),
  })
  const { isLoading: iCreatingSteps, startFetch: _updateSteps } = useFetch({
    action: updateAutomation,
    isOpenApi: true,
    onComplete: () => history.push('/settings/automations'),
    onError: (err) => toastr.error(err),
  })

  const handleSubmit = useCallback(
    (values) => {
      const payload = {
        id,
        currentAutomation,
        name: values.name,
        description: values.description,
        isPublished: values.isPublished,
        steps: transformStepsRequest(values.steps),
        isTemplate: false,
      }
      if (!id) {
        _createAutomation(payload)
      } else {
        _updateSteps(payload)
      }
    },
    [_createAutomation, _updateSteps, currentAutomation, id],
  )

  if (isLoading)
    return (
      <Container fluid className='tw-px-0'>
        <Head title='Automation set up' />
        <Loader />
      </Container>
    )

  return (
    <FormProvider {...methods}>
      <Container fluid className='tw-px-0'>
        <Head title='Automation set up' />
        <ModalHeader noExit>
          <div className='tw-flex tw-flex-row tw-gap-2'>
            <div className='tw-flex tw-flex-1 tw-items-center tw-justify-center'>
              <TitleEditText />
            </div>
            <Button
              onClick={() => setQuitModal(true)}
              outline
              color='secondary'
            >
              Quit
            </Button>
            <Button
              outline
              loading={isCreating || iCreatingSteps}
              onClick={methods.handleSubmit(handleSubmit)}
            >
              Save
            </Button>
            <Button
              loading={isCreating || iCreatingSteps}
              onClick={() => {
                methods.setValue('isPublished', true)
                methods.handleSubmit(handleSubmit)()
              }}
              iconRight={<Play size={15} />}
            >
              Publish
            </Button>
          </div>
        </ModalHeader>
        <div className='tw-m-5 tw-grid tw-grid-cols-3 tw-gap-10'>
          <div />
          <div>
            {stepsField.fields.map((step, index, l) => (
              <Fragment key={step.id}>
                {step.type === 'trigger' ? (
                  <Trigger {...step} index={index} />
                ) : (
                  <>
                    <Divider
                      onClick={({ type }) => stepsField.insert(index, { type })}
                    />
                    {step.type === 'action' ? (
                      <Action {...step} stepsField={stepsField} index={index} />
                    ) : (
                      <Delay {...step} stepsField={stepsField} index={index} />
                    )}

                    {index === l.length - 1 ? (
                      <Divider
                        onClick={({ type }) => stepsField.append({ type })}
                        last
                      />
                    ) : null}
                  </>
                )}
              </Fragment>
            ))}
          </div>

          <div>
            <Card className='tw-gap-2 tw-p-4'>
              <Funnel size={25} className='tw-text-primary' />
              <span className='tw-text-xl tw-font-medium'>Filters</span>
              <span>
                Make your alerts more specified based on country, entity, and
                more.
              </span>

              <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-2'>
                <AddFilterButtons filters={filters.all} />
                <Controller
                  name='filters'
                  control={methods.control}
                  render={({ field: { value } }) => (
                    <>
                      {entries(value)?.map(([name]) => (
                        <FilterButtons name={name} key={`filters-${name}`} />
                      ))}
                    </>
                  )}
                />
              </div>
            </Card>
          </div>
        </div>
      </Container>
      <QuitConfirmModal
        isOpen={quitModal}
        toggle={() => setQuitModal((prevState) => !prevState)}
      />
    </FormProvider>
  )
}
