import { ArrowLeft } from '@phosphor-icons/react'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import Button from '../../../../components/ui/button'
import {
  SideMenu,
  sideMenuGridClass,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import { withdrawCurrencies } from '../../../../services/api'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import { AddBankAccountV2Form } from '../../../withdrawProcess/add-bank-account-v2'
import {
  getBankAccountData,
  useCreateVendorBankAccount,
} from '../../../withdrawProcess/use-bank-account-data'

export function useBankSideMenu() {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggle = () => setIsOpen((open) => !open)

  return { isAddBankOpen: isOpen, toggleAddBank: toggle }
}

const addBankAccountFormId = 'addBankAccountFormId'
export function AddBankSideMenu({ isOpen, toggle, vendor, updateVendors }) {
  const user = useSelector((state) => state.Account?.user)

  const profileCurrency = useSelector(
    (state) => state.userProfile?.userProfile?.company?.currency?.code,
  )

  const { data: currencies } = useFetch(
    {
      action: withdrawCurrencies,
      autoFetch: true,
      body: { currency: profileCurrency },
      initResult: [],
    },
    [profileCurrency],
  )

  const formMethods = useForm()
  const { handleSubmit, control } = formMethods

  const { createBankAccount, isLoading: isCreatingBankAccount } =
    useCreateVendorBankAccount({
      successMessage: [
        `You successfully edited the bank details for ${vendor?.name}`,
        'Bank details added',
      ],
      onSuccess: () => {
        toggle?.()
        updateVendors?.()
      },
    })

  if (!isOpen) return null

  function onSubmit(values) {
    const bankAccountData = getBankAccountData(values, {
      user: { firstName: user?.first_name, lastName: user?.last_name },
    })

    createBankAccount({ ...bankAccountData, vendorId: vendor?.id })
  }

  return (
    <SideMenu
      isOpen
      onClose={toggle}
      preventScrollBehavior={false}
      itemListClassName={sideMenuGridClass()}
    >
      <SideMenu.Header toggle={toggle}>
        <div className='tw-flex tw-items-start tw-gap-2'>
          <button
            className='tw-flex tw-flex-shrink-0 tw-rounded !tw-p-1.5 tw-text-secondary-100 tw-transition-colors hover:tw-bg-surface-20'
            aria-label='Back to details'
            onClick={toggle}
          >
            <ArrowLeft size={20} />
          </button>
          <div>
            <h4 className='tw-mb-0 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Bank details
            </h4>
            <h5 className='tw-mb-0 tw-text-sm tw-font-medium tw-text-text-80'>
              Edit vendor bank details
            </h5>
          </div>
        </div>
      </SideMenu.Header>
      <SideMenu.Body>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} id={addBankAccountFormId}>
            <AddBankAccountV2Form
              control={control}
              currency={profileCurrency}
              currencies={currencies?.map((c) => mapCurrencyToOption(c))}
              className='tw-pt-2'
              fieldsClassName='!tw-grid-cols-1'
            />
          </form>
        </FormProvider>
      </SideMenu.Body>
      <SideMenu.Footer className='tw-sticky tw-bottom-0'>
        <Button
          type='button'
          onClick={toggle}
          outline
          color='light'
          disabled={isCreatingBankAccount}
        >
          Cancel
        </Button>
        <Button
          formId={addBankAccountFormId}
          type='submit'
          disabled={isCreatingBankAccount}
          loading={isCreatingBankAccount}
        >
          Save
        </Button>
      </SideMenu.Footer>
    </SideMenu>
  )
}
