import { apiV2, getConfigV2 } from './api'

export const createAutomation = (token, data) => {
  return apiV2.post(
    `/automation/api/v1/workflow`,
    {
      name: data.name,
      isTemplate: data.isTemplate,
      description: data.description,
    },
    getConfigV2(token),
  )
}

export const updateAutomation = (token, data) => {
  return apiV2.patch(
    `/automation/api/v1/workflow/${data.id}`,
    { name: data.name, isPublished: data.isPublished },
    getConfigV2(token),
  )
}

export const createAutomationFromTemplate = (token, data) => {
  return apiV2.post(
    `/automation/api/v1/workflow/from-template`,
    data,
    getConfigV2(token),
  )
}

export const getAutomations = (token, data) => {
  return apiV2.get(
    `/automation/api/v1/workflow`,
    getConfigV2(token, null, data),
  )
}

export const getAutomationDetail = (token, data) => {
  return apiV2.get(
    `/automation/api/v1/workflow/${data.id}`,
    getConfigV2(token, null, data),
  )
}

export const updateSteps = (token, data) => {
  return apiV2.put(
    `/automation/api/v1/workflow/${data.id}/steps`,
    { steps: data.steps },
    getConfigV2(token),
  )
}
