import {
  FunnelSimple,
  Invoice,
  Money,
  Plus,
  Storefront,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import toastr from 'toastr'
import { Link, useLocation } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { format } from 'date-fns'

import { Avatar, cn } from 'ui'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledSelect from '../../../components/ControlledSelect'
import Head from '../../../components/head'
import { PageNav, useActiveTab } from '../../../components/page-nav'
import SearchBar from '../../../components/SearchBar'
import Button from '../../../components/ui/button'
import { CheckItem } from '../../../components/ui/check-item'
import DataTable from '../../../components/ui/data-table'
import PageHeading from '../../../components/ui/page-heading'
import Shimmer from '../../../components/ui/shimmer'
import { useFetch } from '../../../helpers/hooks'
import { getBills, getVendors } from '../../../services/api-bill-payments'
import { mapListToOption } from '../../../utils/map-to-option'
import { EmptyState } from '../../review-center/empty-state'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import BillCategoryIcon from '../bill-category-icon'
import BillDetailsButton, {
  BillDueDate,
  BillStatusBadge,
} from './bill-details-button'
import Pagination from '../../../components/ui/pagination'

export const BILL_STATUS = {
  DRAFT: 'draft',
  PENDING: 'pending_approval',
  READY: 'pending_payment',
  COMPLETED: 'completed',
}

const PageHeader = () => (
  <PageHeading className='tw-mb-4'>
    <PageHeading.Title>Bills</PageHeading.Title>
    <PageHeading.ActionGroup className='tw-flex-wrap tw-gap-x-2'>
      <Button
        className='!tw-border-0 !tw-bg-white !tw-text-black'
        icon={<Storefront size={20} />}
        to='/bills/vendors'
        tag={Link}
      >
        Manage Vendors
      </Button>

      <Button icon={<Plus size={20} />} tag={Link} to='/bills/create'>
        Add Invoice
      </Button>
    </PageHeading.ActionGroup>
  </PageHeading>
)

const tabsData = [
  {
    label: 'Draft',
    key: BILL_STATUS.DRAFT,
  },
  { label: 'For Approval', key: BILL_STATUS.PENDING },
  {
    label: 'Ready To Pay',
    key: BILL_STATUS.READY,
  },
  { label: 'Completed', key: BILL_STATUS.COMPLETED },
]

const TabHeader = ({ activeTab }) => (
  <PageNav className='!tw-mb-4 !tw-flex-wrap tw-rounded tw-bg-white'>
    {tabsData.map((data) => (
      <PageNav.Item key={data.key}>
        <PageNav.Link
          to={`/bills?tab=${data.key}`}
          tag={Link}
          isActive={activeTab === data.key}
          className='!tw-flex tw-items-center tw-gap-2'
        >
          {data.label}
        </PageNav.Link>
      </PageNav.Item>
    ))}
  </PageNav>
)

export const BillInfoText = ({ title, subTitle, className }) => (
  <span
    className={cn(
      'tw-text-right tw-text-sm tw-font-normal tw-text-text-60',
      className,
    )}
  >
    <div className='tw-text-text'>{title}</div>
    {subTitle}
  </span>
)

const Body = ({ activeTab }) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [minAmount, setMinAmount] = useState()
  const [maxAmount, setMaxAmount] = useState()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const vendorId = searchParams.get('vendor')

  const { control } = useForm({
    defaultValues: { vendor: vendorId ? Number(vendorId) : undefined },
  })
  const { vendor, due_date: dueDate, category } = useWatch({ control })
  const integrationCategories = useSelector(
    (state) => state.Layout?.staticData?.integration_categories,
  )
  const user = useSelector((state) => state.Account?.user)

  const {
    data: bills,
    isLoading,
    startFetch: fetchBills,
    paginator,
  } = useFetch(
    {
      action: getBills,
      autoFetch: true,
      body: {
        vendor_id: vendor,
        due_date: dueDate,
        min_amount: minAmount,
        max_amount: maxAmount,
        category_id: category,
        status: activeTab,
      },
      onError: (error) => toastr.error(error),
    },
    [vendor, dueDate, minAmount, maxAmount, category, activeTab],
  )
  const isAllSelected = selectedItems.length === bills?.length
  const isFiltering = vendor || dueDate || category || maxAmount || minAmount

  const columns = [
    {
      Header: 'Category',
      accessor: 'category_id',
      Cell: ({ cellData }) => (
        <span className='tw-flex tw-gap-2'>
          <BillCategoryIcon category={cellData} />{' '}
          {
            // @todo: Use the category name from backend once it is provided
            integrationCategories?.find((category) => category.id === cellData)
              .name
          }
        </span>
      ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Created on
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'created_at',
      Cell: ({ cellData, rowData }) => (
        <BillInfoText
          title={format(new Date(cellData), 'dd/MM/yyyy')}
          subTitle={
            rowData.creator_name &&
            `By ${rowData.creator_name} ${user.id === rowData.creator_id ? '(You)' : ''}`
          }
          className='tw-text-left'
        />
      ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Due date
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'due_date',
      Cell: ({ cellData }) => <BillDueDate date={cellData} />,
    },
    { Header: 'Vendor', accessor: 'vendor.name' },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Amount
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'amount',
      Cell: ({ rowData }) => {
        const formatter = getCurrencyFormatter(rowData.currency.code)
        return <span>{formatter.format(rowData.amount)}</span>
      },
    },
    {
      Header: 'Invoice ID',
      accessor: 'id',
    },
    activeTab === BILL_STATUS.COMPLETED && {
      Header: 'Payment method',
      accessor: 'payment_method',
    },
    activeTab === BILL_STATUS.PENDING
      ? {
          Header: 'Next Approver',
          accessor: 'next_approver',
          Cell: () => (
            <span className='tw-flex tw-gap-2'>
              <span className='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-secondary-20 tw-px-2 tw-py-1 tw-text-sm tw-font-bold tw-text-black'>
                2/3
              </span>

              <span className='tw-flex tw-items-center tw-gap-1 tw-bg-secondary-20 tw-px-2 tw-py-1'>
                <Avatar
                  photo='https://cdn.platinumlist.net/upload/artist/bovi_646-orig1535972582.jpg'
                  name='Willie Crowder'
                />
                <span className='tw-text-sm tw-font-semibold tw-text-black'>
                  Willie Crowder
                </span>
              </span>
            </span>
          ),
        }
      : {
          Header: 'Status',
          accessor: 'status.name',
          Cell: ({ cellData }) => <BillStatusBadge status={cellData} />,
        },
    {
      Cell: ({ rowData }) => (
        <BillDetailsButton
          bill={rowData}
          fetchBills={fetchBills}
          activeTab={tabsData.find((tab) => tab.key === activeTab)}
        />
      ),
    },
    activeTab === BILL_STATUS.READY && {
      Header: (
        <Button
          color='link'
          onClick={() => setSelectedItems([])}
          className='tw-w-28'
        >
          {isAllSelected ? `Deselect ` : 'Select '} All
        </Button>
      ),
      Cell: ({ rowData }) => {
        const isItemSelected = selectedItems.find(
          (item) => item.id === rowData.id,
        )
        return (
          <span className='tw-flex tw-justify-center'>
            <CheckItem
              className='tw-w-fit'
              checked={isAllSelected || isItemSelected}
              onChange={() => {
                setSelectedItems(
                  isItemSelected
                    ? [
                        ...selectedItems.filter(
                          (item) => item.id !== rowData.id,
                        ),
                      ]
                    : [...selectedItems, rowData],
                )
              }}
            />
          </span>
        )
      },
    },
  ].filter(Boolean)

  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
      <BodyHeader isLoading={isLoading} />
      {!(bills?.length === 0 && !isFiltering) && (
        <FilterRow
          isLoading={isLoading && !isFiltering}
          control={control}
          minAmount={{ value: minAmount, onChange: setMinAmount }}
          maxAmount={{ value: maxAmount, onChange: setMaxAmount }}
          categories={integrationCategories}
        />
      )}
      {isLoading ? (
        <span className='tw-flex tw-flex-col tw-gap-2'>
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
        </span>
      ) : bills?.length === 0 ? (
        <EmptyState
          title='You have no bill to show'
          textElement={
            !isFiltering && activeTab === BILL_STATUS.DRAFT
              ? 'You can click Add Invoice button and create one.'
              : ''
          }
          pictureElement={
            <Invoice
              weight='duotone'
              className='tw-mb-4 tw-fill-primary'
              size={250}
            />
          }
          className='tw-shadow-none'
        />
      ) : (
        <>
          <DataTable columns={columns} data={bills} striped responsive />
          <span className='tw-flex tw-justify-end'>
            <Pagination
              activePage={paginator?.current_page ?? 1}
              onChange={(page) => searchParams.set('page', page)}
              itemsCountPerPage={paginator?.per_page}
              totalItemsCount={paginator?.total ?? 0}
            />
          </span>
        </>
      )}
    </div>
  )
}

// @todo: Remove default amount once BE is ready
const BodyHeader = ({ isLoading, amount = 1560 }) => {
  const { activeTab } = useActiveTab({ defaultTab: BILL_STATUS.DRAFT })
  const formatter = getCurrencyFormatter() // enter currency code

  return (
    <span className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-2'>
      <span>
        {!isLoading ? (
          <>
            <div className='tw-text-2xl'>
              {activeTab === BILL_STATUS.DRAFT ? 'Draft' : 'Ready to pay'}
            </div>
            {!!amount && (
              <span className='tw-text-sm tw-text-text-80'>
                {formatter.format(amount)}
              </span>
            )}
          </>
        ) : (
          <>
            <Shimmer className='tw-mb-2' width={130} />
            <Shimmer height={32} />
          </>
        )}
      </span>

      {activeTab === BILL_STATUS.READY && (
        <Button disabled={isLoading || true} icon={<Money />}>
          Select To Pay
        </Button>
      )}
    </span>
  )
}

const FilterRowWrapper = ({ children, className }) => (
  <span
    className={cn(
      'tw-flex tw-flex-wrap tw-gap-x-2 tw-gap-y-4 [&>*]:!tw-w-full [&>*]:md:!tw-min-w-60 [&>*]:md:tw-flex-1',
      className,
    )}
  >
    {children}
  </span>
)

const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    height: '52px',
    borderColor: 'var(--surface-30)',
    boxShadow: state.isFocused ? '0 0 0 2px var(--surface-30)' : 'none',
    '&:hover': {
      borderColor: 'var(--surface-30)',
      cursor: 'pointer',
    },
  }),
}

const FilterRow = ({
  isLoading,
  control,
  minAmount,
  maxAmount,
  categories,
}) => {
  const { data: vendors, isLoading: fetchingVendors } = useFetch({
    action: getVendors,
    autoFetch: true,
    onError: (error) => toastr.error(error),
  })

  if (isLoading) {
    return (
      <FilterRowWrapper>
        {new Array(5).fill(0).map((_, index) => (
          <Shimmer key={index} />
        ))}
      </FilterRowWrapper>
    )
  }

  return (
    <FilterRowWrapper>
      <ControlledDatePicker
        placeholder='Due Date'
        wrapperClassName='[&>div>div>div]:tw-h-[52px] [&>div>div>div]:!tw-flex [&>div>div>div]:tw-items-center'
        control={control}
        name='due_date'
        clearable
      />

      <ControlledSelect
        options={categories?.map(mapListToOption)}
        placeholder='Category'
        control={control}
        name='category'
        isClearable
        styles={selectStyle}
        wrapperClassName='tw-border-surface-30 [&>div>:nth-child(3)]:focus:tw-shadow-surface-30'
      />

      <ControlledSelect
        isDisabled={fetchingVendors}
        options={vendors?.map(mapListToOption)}
        placeholder='Vendor'
        control={control}
        name='vendor'
        styles={selectStyle}
        isClearable
      />

      <SearchBar
        type='number'
        placeholder='Min Amount'
        inputClassName='!tw-h-[52px] !tw-px-3'
        hideIcon
        query={minAmount.value}
        onQueryChanged={minAmount.onChange}
      />

      <SearchBar
        type='number'
        placeholder='Max Amount'
        inputClassName='!tw-h-[52px] !tw-px-3'
        hideIcon
        query={maxAmount.value}
        onQueryChanged={maxAmount.onChange}
      />
    </FilterRowWrapper>
  )
}

export default function Bills() {
  const { activeTab } = useActiveTab({ defaultTab: BILL_STATUS.DRAFT })

  return (
    <div className='page-content'>
      <Head title='Bills' />
      <PageHeader />
      <TabHeader activeTab={activeTab} />
      <Body activeTab={activeTab} />
    </div>
  )
}
