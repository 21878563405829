import React from 'react'
import { Avatar, cn } from 'ui'
import ContractRef from '../AdminPanel/components/ContractRef'
import { Info } from '@phosphor-icons/react'
import { UncontrolledTooltip } from 'reactstrap'

export function ContractorHeader({
  name,
  flag,
  flagIsAbsolute,
  contractId,
  photo,
  withHeaderStyle = true,
}) {
  return (
    <div
      className={cn(
        'tw-flex tw-items-center tw-justify-between tw-border-surface-30 tw-p-6',
        withHeaderStyle && 'tw-border-b',
      )}
    >
      <div>
        <div
          className={cn(
            'tw-font-semibold',
            withHeaderStyle ? 'tw-text-xl' : 'tw-text-base',
          )}
        >
          {name}
        </div>
        <div
          className={cn(
            'tw-text-primary-100',
            withHeaderStyle ? 'tw-text-sm' : 'tw-text-xs',
          )}
        >
          <ContractRef contractId={contractId} withHash />
        </div>
      </div>

      <Avatar
        name={name}
        size='xl'
        flag={flag}
        flagIsAbsolute={flagIsAbsolute}
        photo={photo}
      />
    </div>
  )
}

export function DetailsInfoList({ items, className, title }) {
  const info = items?.filter(Boolean)

  return (
    <div className={cn('tw-p-6', className)}>
      {title}

      {info?.map(
        ({ label, value, className, valueClassName, icon, tip }, index) => {
          return (
            <div
              key={index}
              className={cn(
                'tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 tw-border-b tw-border-surface-30 tw-py-4 tw-text-sm',
                className,
              )}
            >
              <div className='tw-flex tw-items-center tw-gap-4 tw-text-text-100'>
                {!icon ? null : <div className='tw-text-text-60'>{icon}</div>}
                <div className='tw-text-text-100'>{label}</div>
                {tip && (
                  <>
                    <Info
                      size={16}
                      className='tw-fill-secondary-50'
                      id={label.replaceAll(' ', '_')}
                    />
                    <UncontrolledTooltip target={label.replaceAll(' ', '_')}>
                      {tip}
                    </UncontrolledTooltip>
                  </>
                )}
              </div>

              <div
                className={cn(
                  'tw-max-w-full tw-overflow-hidden tw-text-wrap tw-text-end tw-font-semibold',
                  valueClassName,
                )}
              >
                {value}
              </div>
            </div>
          )
        },
      )}
    </div>
  )
}
