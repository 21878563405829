import lowerCase from 'lodash/lowerCase'
import { triggers } from './constants'
import set from 'lodash/set'
import compact from 'lodash/compact'
import upperCase from 'lodash/upperCase'
import {
  updateAutomation as updateAutomationApi,
  updateSteps as updateStepsApi,
} from '../../../../services/api-automations'
import isEqual from 'lodash/isEqual'

export const defaultValue = {
  steps: [{ type: 'trigger' }, { type: 'action' }],
  name: 'Untitled automation',
  isPublished: false,
  description: '',
}

export function transformAutomationResponse(res) {
  const transformSteps = (_steps) => {
    let hasTrigger = false
    let hasAction = false
    const steps = _steps.map((s) => {
      const type = lowerCase(s.type)
      const step = { type }
      if (type === 'trigger') {
        const trigger = triggers.find((t) => t.value === s.trigger.event)
        set(step, 'event', trigger)
        hasTrigger = true
      }

      if (type === 'action') {
        const message = s?.action?.customMessage
        const targets = s?.action?.notificationTargets ?? []
        set(step, 'subject', message?.emailSubject ?? '')
        set(step, 'body', message?.emailBody ?? '')
        set(
          step,
          'to',
          targets.map((t) => t.email),
        )
        hasAction = true
      }

      if (type === 'delay') {
        const minute = s.delay.minutes
        set(step, 'numberOfDays', minute)
      }
      return step
    })
    if (!hasTrigger) steps.unshift(defaultValue.steps[0])
    if (!hasAction) steps.push(defaultValue.steps[1])
    return steps
  }
  return {
    name: res?.name ?? '',
    description: res?.description ?? '',
    isPublished: res?.isPublished ?? false,
    steps: transformSteps(res?.steps ?? []),
  }
}

export function transformStepsRequest(_steps) {
  return compact(
    _steps.map((s) => {
      const step = { type: upperCase(s.type) }
      if (s.type === 'trigger') {
        const value = s?.event?.value
        if (!value) return null
        set(step, 'trigger.event', s?.event?.value)
      }
      if (s.type === 'action') {
        const emailSubject = s?.subject
        const emailBody = s?.body
        const to = s?.to ?? []
        if (!emailBody || !emailSubject) return null
        set(step, 'action.type', 'SEND')
        set(step, 'action.channel', 'EMAIL')
        set(
          step,
          'action.notificationTargets',
          to.map((t) => ({
            email: t,
            name: t.split('@')[0],
          })),
        )
        set(step, 'action.customMessage', {
          emailSubject,
          emailBody,
        })
      }

      if (s.type === 'delay') {
        if (!s.numberOfDays) return null
        set(step, 'delay.minutes', s.numberOfDays)
      }
      return step
    }),
  )
}

export async function updateAutomation(
  token,
  { id, steps, name, isPublished, currentAutomation },
) {
  const isStepsSame = isEqual(
    steps,
    transformStepsRequest(currentAutomation.steps),
  )
  const isAutomationSame = isEqual(
    { name, isPublished },
    {
      name: currentAutomation.name,
      isPublished: currentAutomation.isPublished,
    },
  )

  const promises = []
  if (!isStepsSame) promises.push(updateStepsApi(token, { steps, id }))
  if (!isAutomationSame)
    promises.push(updateAutomationApi(token, { id, name, isPublished }))

  return await Promise.all(promises)
}
